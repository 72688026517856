<template>
  <topBanner />
  <div class="container mt-5 mb-5">
    <section class="faq-container">
      <div class="faq-one" v-for="item in faqData" :key="item.id">
        <h1 class="faq-page">{{ item.question }}</h1>
        <div class="faq-body">
          <p>
            {{ item.answer }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import data from "../Data/data.json";
import topBanner from "../components/innerpagestopbanner/innerTop.vue";
export default {
  components: {
    topBanner,
  },
  data() {
    return {
      faqData: "",
    };
  },
  created() {
    this.faqData = data.faq;
  },
  mounted() {
    this.makeAccordian();
  },
  methods: {
    makeAccordian() {
      var faq = document.getElementsByClassName("faq-page");
      var i;
      for (i = 0; i < faq.length; i++) {
        faq[i].addEventListener("click", function() {
          this.classList.toggle("active1");
          var body = this.nextElementSibling;
          if (body.style.display === "block") {
            body.style.display = "none";
          } else {
            body.style.display = "block";
          }
        });
      }
    },
  },
};
</script>

<style>
/* css for accordians of faq */
.faq-heading {
  padding: 20px 60px;
}
.faq-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.faq-page {
  background-color: #1c4d94;
  color: #fff;
  cursor: pointer;
  padding: 20px 20px;
  border: none;
  outline: none;
  transition: 0.4s;
  margin: auto;
  font-size: 18px;
  border-bottom: 1px solid #fff;
}
.faq-body {
  margin: auto;
  padding: auto;
}
.active1,
.faq-page:hover {
  background-color: #1c4d94;
}
.faq-body {
  padding: 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}
.faq-page:after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-size: 20px;
  color: #fff !important;
  float: right;
  margin-left: 5px;
}
.active1:after {
  content: "\f106";
  font-family: "Font Awesome 5 Pro";
  color: #fff;
}
</style>
